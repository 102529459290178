<template>
  <div class="mobileHome">
    <div class="columnTop">
      <div>
        <h1>专业的电商运营</h1>
        <p>长期服务于淘宝、天猫、京东、拼多多等平台，提供全方位的店铺运营服务。</p>
        <a @click="scrollIntoView">预约免费诊断</a>
      </div>
      <img src="../../assets/img/home_0.png" alt srcset />
    </div>
    <div class="columnContent column_serviceA">
      <div class="column-title">
        <div class="blue-bg-hr"></div>
        <h2>服务内容</h2>
      </div>
      <div class="columnContent-flex">
        <div>
          <img src="../../assets/img/serviceLogo/taobao.png" />
          <p class="top_12">淘宝代运营</p>
        </div>
        <div>
          <img src="../../assets/img/serviceLogo/tianmao.png" />
          <p class="top_12">天猫代运营</p>
        </div>
        <div>
          <img src="../../assets/img/serviceLogo/pinduoduo.png" />
          <p class="top_12">拼多多代运营</p>
        </div>
        <div>
          <img src="../../assets/img/serviceLogo/jingdong.png" />
          <p class="top_12">京代运营</p>
        </div>
      </div>
      <div class="columnContent-flex bg-ccc">
        <div class="service_card">
          <div class="service_card_img">
            <img src="../../assets/img/serviceLogo/zhitongche.png" />
          </div>
          <p>直通车</p>
          <p>投放托管</p>
        </div>
        <div class="service_card">
          <div class="service_card_img">
            <img src="../../assets/img/serviceLogo/chaojituijian.png" />
          </div>
          <p>超级推荐投放托管</p>
        </div>
        <div class="service_card">
          <div class="service_card_img">
            <img src="../../assets/img/serviceLogo/juliangyinqing.png" />
          </div>
          <p>巨量引擎投放托管</p>
        </div>
      </div>
    </div>
    <div class="columnTeam">
      <img src="../../assets/img/home_1.png"/>
      <div class="columnTeamContent">
        <h2>成熟团队 专业用心</h2>
        <p>12年电商经验为店铺业绩保驾护航。熟悉
          天猫、京东、拼多多等个平台玩法擅长直
          通车、超级推荐、精准通等推广工具。</p>
      </div>
    </div>
    <div class="columnTeam bg-f3f9ff">
      <div class="columnTeamContent">
        <h2>专人操盘 专项运营</h2>
        <p>每周汇总运营数据报表</p>
        <p>根据当周数据进行行业对比</p>
        <p>定期将数据情况向甲方进行汇报</p>
      </div>
      <img src="../../assets/img/home_2.png"/>
    </div>
    <div class="columnTeam">
      <img src="../../assets/img/home_3.png"/>
      <div class="columnTeamContent">
        <h2>运营周报 定期汇报</h2>
        <p>每周汇总运营数据报表</p>
        <p>根据当周数据进行行业对比</p>
        <p>定期将数据情况向甲方进行汇报</p>
      </div>
    </div>
    <div class="columnTeam bg-f3f9ff">
      <div class="columnTeamContent">
        <h2>知己知彼 百战不殆</h2>
        <p>根据自身定位锁定竞品</p>
        <p>持续跟踪竞争对手运营数据</p>
        <p>根据竞品运营情况及时策略调整</p>
      </div>
      <img src="../../assets/img/home_4.png"/>
    </div>
    <div class="column_cooperativeBrand">
      <div class="column-title">
        <div class="blue-bg-hr"></div>
        <h2>合作品牌</h2>
      </div>
      <div class="bg_tv">
        <div class="imgs-container">
          <div class="imgs-containers imgs-container_1" :style="handleStyle(0)"></div>
          <div class="imgs-containers imgs-container_2" :style="handleStyle(1)"></div>
          <div class="imgs-container_3 imgs-containers" :style="handleStyle(2)"></div>
          <div class="imgs-container_4 imgs-containers" :style="handleStyle(3)"></div>
          <div class="imgs-container_5 imgs-containers" :style="handleStyle(4)"></div>
          <div class="imgs-container_6 imgs-containers" :style="handleStyle(5)"></div>
          <div class="imgs-container_7 imgs-containers" :style="handleStyle(6)"></div>
          <div class="imgs-container_8 imgs-containers" :style="handleStyle(7)"></div>
          <div class="imgs-container_9 imgs-containers" :style="handleStyle(8)"></div>
          <div class="imgs-container_10 imgs-containers" :style="handleStyle(9)"></div>
          <div class="imgs-container_11 imgs-containers" :style="handleStyle(10)"></div>
          <div class="imgs-container_12 imgs-containers" :style="handleStyle(11)"></div>
          <div class="imgs-container_13 imgs-containers" :style="handleStyle(12)"></div>
          <div class="imgs-container_14 imgs-containers" :style="handleStyle(13)"></div>
          <div class="imgs-container_15 imgs-containers" :style="handleStyle(14)"></div>
          <div class="imgs-container_16 imgs-containers" :style="handleStyle(15)"></div>
          <div class="imgs-container_17 imgs-containers" :style="handleStyle(16)"></div>
          <div class="imgs-container_32 imgs-containers" :style="handleStyle(17)"></div>
          <div class="imgs-container_19 imgs-containers" :style="handleStyle(18)"></div>
          <div class="imgs-container_20 imgs-containers" :style="handleStyle(19)"></div>
          <div class="imgs-container_21 imgs-containers" :style="handleStyle(20)"></div>
          <div class="imgs-container_22 imgs-containers" :style="handleStyle(21)"></div>
          <div class="imgs-container_23 imgs-containers" :style="handleStyle(22)"></div>
          <div class="imgs-container_24 imgs-containers" :style="handleStyle(23)"></div>
          <div class="imgs-container_25 imgs-containers" :style="handleStyle(24)"></div>
          <div class="imgs-container_27 imgs-containers" :style="handleStyle(25)"></div>
          <div class="imgs-container_26 imgs-containers" :style="handleStyle(26)"></div>
          <div class="imgs-container_28 imgs-containers" :style="handleStyle(27)"></div>
          <div class="imgs-container_29 imgs-containers" :style="handleStyle(28)"></div>
          <div class="imgs-container_30 imgs-containers" :style="handleStyle(29)"></div>
          <div class="imgs-container_31 imgs-containers" :style="handleStyle(30)"></div>
          <div class="imgs-container_18 imgs-containers" :style="handleStyle(31)"></div>
          <div class="imgs-container_33 imgs-containers" :style="handleStyle(32)"></div>
        </div>
      </div>
    </div>
    <div class="column_diagnosis">
      <div class="column-title">
        <div class="blue-bg-hr dingyue_box"></div>
        <h2>免费诊断</h2>
      </div>
      <p class="column_diagnosis_p">
        请准确填写以下内容，我们将安排对应类目的运营经理
        为您诊断店铺，我们将于半个小时内与您取得联系。
      </p>
      <div class="column_diagnosis_form">
        <p class="trueplaceholder">
          <input
              type="text"
              style="color:#666666"
              v-model="shopMsg.shopUrl"
              placeholder="请输入店铺地址"
              @keyup="changePhone('shopUrl')"
              @blur="changePhone('shopUrl')"
          />
        </p>
        <p class="input_center trueplaceholder">
          <input
              type="text"
              @keyup="changePhone('name')"
              @blur="changePhone('name')"
              v-model="shopMsg.name"
              style="color:#666666"
              placeholder="请输入您的称呼"
          />
        </p>
        <p class="input_center" :class="this.rules.phone?'trueplaceholder':'falseplaceholder'">
          <input
              type="text"
              :style="this.rules.phone?'color:#666666':'color:red'"
              @keyup="changePhone('phone')"
              @blur="changePhone('phone')"
              v-model="shopMsg.phone"
              placeholder="请输入联系电话"
          />
        </p>
        <button @click="submitForm">预约诊断</button>
      </div>
    </div>
    <div class="column_footer">
      <div>
        <img src="../../assets/img/QRCODE_160x160.png" />
      </div>
      <p>
        Copyright © 2018-2021 yunyingshou
      </p>
      <p>
        <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备20017027-3号</a>
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return{
      style: [
        {
          x: -36.3232,
          y: 20.8388,
          oldy: 20.8388,
          up: false
        },
        {
          x: 208.916,
          y: 143.536,
          oldy: 143.936,
          up: true
        },
        {
          x: 273.335,
          y: 170.569,
          oldy: 170.569,
          up: false
        },
        {
          x: 64.89,
          y: 46.9941,
          oldy: 46.9941,
          up: false
        },
        {
          x: 759.79,
          y: 153.47,
          oldy: 153.47,
          up: true
        }, //5
        {
          x: 578.3,
          y: 164.846,
          oldy: 164.846,
          up: false
        },
        {
          x: 1085.3,
          y: 210,
          oldy: 216,
          up: false
        },
        {
          x: 996.71,
          y: 192.077,
          oldy: 191.077,
          up: false
        },
        {
          x: 4.319,
          y: 192.698,
          oldy: 182.698,
          up: true
        },
        {
          x: 1065.18,
          y: 96.954,
          oldy: 96.954,
          up: true
        }, //10
        {
          x: 663.14,
          y: 95.698,
          oldy: 97.592,
          up: false
        },
        {
          x: 42.88,
          y: 101.847,
          oldy: 102.884,
          up: false
        },
        {
          x: 1249.89,
          y: 50.288,
          oldy: 50.288,
          up: true
        },
        {
          x: 1148.75,
          y: 150.369,
          oldy: 150.369,
          up: false
        },
        {
          x: 988.42,
          y: 54.393,
          oldy: 52.393,
          up: false
        }, //15
        {
          x: 450.11,
          y: 174.424,
          oldy: 179.424,
          up: false
        },
        {
          x: 122.224,
          y: 180.087,
          oldy: 180.087,
          up: false
        },
        {
          x: 370.766,
          y: 154.364,
          oldy: 150.364,
          up: true
        },
        {
          x: 473.786,
          y: 94.63,
          oldy: 86.63,
          up: false
        },
        {
          x: 542.89,
          y: 39.2581,
          oldy: 37.2581,
          up: true
        }, //20
        {
          x: 736.92,
          y: 78.759,
          oldy: 78.759,
          up: true
        },
        {
          x: 785.72,
          y: 27.6695,
          oldy: 27.6695,
          up: false
        },
        {
          x: 167.091,
          y: 58.631,
          oldy: 54.631,
          up: false
        },
        {
          x: 1338.425,
          y: 120.038,
          oldy: 120.038,
          up: false
        },
        {
          x: 278.951,
          y: 85.782,
          oldy: 85.782,
          up: false
        }, //25
        {
          x: 280.739,
          y: 195.191,
          oldy: 195.191,
          up: false
        },
        {
          x: 611.34,
          y: 76.461,
          oldy: 80.461,
          up: false
        },
        {
          x: 1300.88,
          y: 185.884,
          oldy: 184.884,
          up: false
        },
        {
          x: 405.562,
          y: 45.2548,
          oldy: 44.2548,
          up: false
        },
        {
          x: 1126.54,
          y: 55.13,
          oldy: 55.13,
          up: false
        }, //30
        {
          x: 900.2,
          y: 136.13,
          oldy: 140.13,
          up: false
        },
        {
          x: 672.99,
          y: 180.567,
          oldy: 180.567,
          up: false
        },
        {
          x: 850.2,
          y: 39.187,
          oldy: 30.187,
          up: false
        }
      ],
      shopMsg: {
        shopUrl: "",
        name: "",
        phone: ""
      },
      rules: {
        name: true,
        shopUrl: true,
        phone: true
      }
    }
  },
  methods:{
    actionMove() {
      let _this = this;
      this.timeout = setInterval(() => {
        _this.queryConvertPercent();
      }, 25);
    },
    queryConvertPercent() {
      for (let i = 0; i < this.style.length; i++) {
        if (this.style[i].x > 0) {
          this.style[i].x -= 0.45;
          if (this.style[i].y - this.style[i].oldy > 10) {
            this.style[i].up = true;
          }
          if (this.style[i].y - this.style[i].oldy < -10) {
            this.style[i].up = false;
          }
          if (this.style[i].up) this.style[i].y -= 0.25;
          else this.style[i].y += 0.25;
        } else {
          this.style[i].x = 1350;
          if (this.style[i].y - this.style[i].oldy > 30)
            this.style[i].y -= 0.45;
          else this.style[i].y += 0.45;
        }
      }
    },
    scrollIntoView() {
      this.$el
          .querySelector(".dingyue_box")
          .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleStyle(index) {
      if (index < 10)
        return {
          opacity: 1,
          transform:
              "translate(" +
              this.style[index].x +
              "px," +
              this.style[index].y +
              "px) scale(0.3)"
        };
      else if (index > 10 && index < 21)
        return {
          opacity: 1,
          transform:
              "translate(" +
              this.style[index].x +
              "px," +
              this.style[index].y +
              "px) scale(0.4)"
        };
      else if (index > 21)
        return {
          opacity: 1,
          transform:
              "translate(" +
              this.style[index].x +
              "px," +
              this.style[index].y +
              "px) scale(0.5)"
        };
    },
    submitForm() {
      let regs = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      let passRule = true;
      if (!regs.test(this.shopMsg.phone)) passRule = false;
      if (passRule) {
        axios({
          method: "post",
          url: "https://console.zhishuyao.com/index/tool/apply",
          data: {
            address: this.shopMsg.shopUrl,
            username: this.shopMsg.name,
            mobile: this.shopMsg.phone
          }
        }).then(res => {
          let code = res.data.code;
          if (code == "0") {
            this.showPage = "home";
            this.$message({
              message: "预约成功",
              type: "success"
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        });
      } else {
        this.$message({
          message: "手机号码格式不正确，请重新输入",
          type: "warning"
        });
      }
    },
    changePhone(msg) {
      if (!this.shopMsg[msg]) this.rules[msg] = false;
      else this.rules[msg] = true;
    }
  },
  created() {
    this.actionMove();
  }
}
</script>
<style lang="less">
.mobileHome{
  .column-title{
    display: flex;
    padding-left: 10px;
  }
  h2{
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .blue-bg-hr{
    width: 3px;
    height: 16px;
    background: #44a7fc;
    margin-right: 10px;
  }
  .columnTop{
    background: #ddeaff;
    display: flex;
    height: 175px;
    align-items: center;
    justify-content: space-around;
    div{
      width: 168px;
      h1{
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #333333;
        font-weight: 600;
        line-height: 20px;
      }
      p{
        color: #666666;
        font-size: 12px;
        line-height: 15px;
        margin: 15px 0;
      }
      a{
        display: block;
        width: 105px;
        height: 25px;
        border-radius: 25px;
        color: #ffffff;
        background-color: #44a7fc;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
      }
    }
    img{
      width: 140px;
      height: 120px;
    }

  }
  .columnContent{
    padding: 29px 0 32px;
    .bg-ccc{
      .service_card{
        padding: 11px 0 8px;
        width: 86px;
        border-radius: 5px;
        background: #F8F8F8;
        text-align: center;
        .service_card_img{
          width: 47px;
          height: 47px;
          margin:0 auto 8px;
        }
        p{
          margin: auto;
          width: 51px;
        }
      }
    }
    .columnContent-flex{
      margin-top: 27px;
      display: flex;
      justify-content: space-around;
      text-align: center;
      img{
        width: 47px;
      }
      p{
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 12px;
      }
      .top_12{
        margin-top: 12px;
      }
    }
  }
  .bg-f3f9ff{
    background: #f3f9ff;
  }
  .columnTeam{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 140px;
    img{
      width: 130px;
      margin: 0 10px;
    }
    .columnTeamContent{
      h2{
        margin-bottom: 12px;
      }
      p{
        font-size: 11px;
        line-height: 14px;
        width: 200px;
        color: #666666;
      }
    }
  }
  .column_cooperativeBrand{
    padding-top: 30px;
    .bg_tv {
      //background: url(../../assets/img/bg_center_gray.png);
      //background-repeat: no-repeat;
      height: 350px;
      background-size: 100% 100%;
      position: relative;
      width: 100%;
      overflow: hidden;
      .imgs-container {
        width: 1350px;
        height: 230px;
        left: -200px;
        position: absolute;
        z-index: 2;
        .imgs-containers {
          background: url(../../assets/img/tokens.e93c29d.png);
          background-repeat: no-repeat;
          height: 120px;
          position: absolute;
          width: 120px;
          background-size: 1353px 492px;
        }
        .imgs-container_1 {
          background-position: -0px -0px;
        }
        .imgs-container_2 {
          background-position: -0px -123px;
        }
        .imgs-container_3 {
          background-position: -0px 246px;
        }
        .imgs-container_4 {
          background-position: -0px -369px;
        }
        .imgs-container_5 {
          background-position: -123px -0px;
        }
        .imgs-container_6 {
          background-position: -123px -123px;
        }
        .imgs-container_7 {
          background-position: -123px -246px;
        }
        .imgs-container_8 {
          background-position: -123px -369px;
        }
        .imgs-container_9 {
          background-position: -246px -0px;
        }
        .imgs-container_10 {
          background-position: -246px -123px;
        }
        .imgs-container_11 {
          background-position: -246px -246px;
        }
        .imgs-container_12 {
          background-position: -246px -369px;
        }
        .imgs-container_13 {
          background-position: -369px -0px;
        }
        .imgs-container_14 {
          background-position: -369px -123px;
        }
        .imgs-container_15 {
          background-position: -369px -246px;
        }
        .imgs-container_16 {
          background-position: -369px -369px;
        }
        .imgs-container_17 {
          background-position: -492px -0px;
        }
        .imgs-container_18 {
          background-position: -492px -123px;
        }
        .imgs-container_19 {
          background-position: -492px -246px;
        }
        .imgs-container_20 {
          background-position: -492px -369px;
        }
        .imgs-container_21 {
          background-position: -615px -0px;
        }
        .imgs-container_22 {
          background-position: -615px -123px;
        }
        .imgs-container_23 {
          background-position: -615px -246px;
        }
        .imgs-container_24 {
          background-position: -615px -369px;
        }
        .imgs-container_25 {
          background-position: -738px -0px;
        }
        .imgs-container_26 {
          background-position: -738px -123px;
        }
        .imgs-container_27 {
          background-position: -738px -246px;
        }
        .imgs-container_28 {
          background-position: -738px -369px;
        }
        .imgs-container_29 {
          background-position: -861px -0px;
        }
        .imgs-container_30 {
          background-position: -861px -123px;
        }
        .imgs-container_31 {
          background-position: -861px -246px;
        }
        .imgs-container_32 {
          background-position: -861px -369px;
        }
        .imgs-container_33 {
          background-position: -984px -0px;
        }
        .imgs-container_4 {
          background-position: -984px -123px;
        }
        .imgs-container_4 {
          background-position: -984px -246px;
        }
        .imgs-container_4 {
          background-position: -984px -369px;
        }
      }
    }
  }
  .column_diagnosis{
    .column_diagnosis_p {
      color: #666666;
      //text-align: center;
      width: 311px;
      margin-left: 22px;
      font-size: 12px;
      line-height: 17px;
      margin-top: 15px;
    }
    .column_diagnosis_form {
      padding: 35px 0;
      p {
        margin: auto;
        width: 307px;
        height: 40px;
        //box-shadow: 0px 13px 13px -3px #eae9e9;
        background: #f3f3f3;
        border-radius: 20px;
        input {
          margin: 8px 0 5px 40px;
          background: #f3f3f3;
          height: 23px;
          font-size: 14px;
          line-height: 18px;
          border: 0;
          outline: none;
          width: 180px;
        }
      }
      button {
        width: 307px;
        height: 40px;
        background-color: #44a7fc;
        color: #ffffff;
        border: 0;
        font-size: 15px;
        cursor: pointer;
        outline: none;
        margin:26px auto 0;
        border-radius: 52px;
        display: block;
      }
      .input_center {
        margin-top: 15px;
      }
    }
  }
  .column_footer{
    background: #F8F8F8;
    padding: 19px 0 25px;
    text-align: center;
    div{
      margin:0 auto 17px;
      img{
        width: 70px;
        height: 70px;
      }
    }
    p{
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 18px
    }
  }
  .trueplaceholder {
    ::placeholder {
      /* WebKit browsers */
      color: #999999 !important;
    }
  }
  .falseplaceholder {
    ::placeholder {
      /* WebKit browsers */
      color: red !important;
      color: red;
    }
  }
}
</style>
