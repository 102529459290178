<template>
  <div class="mobile_about" :style="getHight()">
    <div class="column_header">
      <div class="column_content">
        <h2>目标与愿景</h2>
        <div class="column_content_br"></div>
        <p>
          打造流量爆款，精准定位人群
          让运营更高效
        </p>
      </div>
    </div>
    <div class="column_about">
      <div class="column_content">
        <h2>关于运营兽</h2>
        <div class="column_content_br"></div>
        <p>
          我是运营兽，我们的服务涵盖淘宝、拼多多、京东。
          背后有12年的从业经验，强大的团队，以客户的需
          求和商业创新为引擎，让天下没有难做的电商。
        </p>
      </div>
    </div>
    <div class="column_footer">
      <div>
        <img src="../../assets/img/QRCODE_160x160.png" />
      </div>
      <p>
        Copyright © 2018-2021 yunyingshou
      </p>
      <p>
        <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备20017027-3号</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    getHight(){
      return{
        height:document.documentElement.clientHeight-50 +"px"
      }
    }
  }
}
</script>
<style lang="less">
.mobile_about{
  position: relative;
  .column_header{
    background: url("../../assets/img/mobile_about.png") no-repeat;
    background-size: 100% 100%;
    height: 192px;
    overflow: hidden;
    .column_content{
      margin-top: 67px;
      width: 194px;
    }
  }
  .column_about{
    padding: 30px 0 200px;
    .column_content{
      width: 334px;
    }
  }

  .column_footer{
    background: #F8F8F8;
    padding: 19px 0 25px;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0px;
    div{
      margin:0 auto 17px;
      img{
        width: 70px;
        height: 70px;
      }
    }
    p{
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 18px
    }
  }
  .column_content{
    margin-left: 17px;
    h2{
      font-size: 18px;
      line-height: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .column_content_br{
      width: 50px;
      height: 3px;
      background: #44a7fc;
      margin: 17px 0;
    }
    p{
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 17px;
      color: #8D8D8D;
    }
  }

}
</style>
