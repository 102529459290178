<template>
  <div class="about">
    <div class="blue_bg"></div>
    <div class="about-content">
        <div class="target-con">
            <div class="target-title">目标与愿景</div>
            <div class="target-desc">打造流量爆款，精准定位人群，让运营更高效</div>
        </div>
        <div class="about-yunyingshou-text">关于运营兽</div>
        <p>我是运营兽，我们的服务涵盖淘宝、拼多多、京东。背后有12年的从业经验，强大的团队，以客户的需求和商业创新为引擎，让天下没有难做的电商。</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.about {
  .blue_bg {
    position: absolute;
    right: 20px;
    width: 1005px;
    height: 557px;
    top: -37px;
    background: url(../../assets/img/serviceLogo/bg_about_blue.01a9a8d.svg) 0px -250px no-repeat;
  }
  width: 1200px;
  height: 600px;
  margin: auto;
  padding-top: 100px;
  .about-content{
      width: 100%;
    //   margin-top: 100px;
      .target-con{
          .target-title{
              font-size: 38px;
                height: 53px;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
          }
          .target-desc{
                  margin: 0;
                margin-top: 56px;
                font-size: 18px;
          }
      }
      .about-yunyingshou-text{
              -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            height: 53px;
            font-size: 38px;
            margin-top: 166px;
      }
      p{
        font-size: 18px;
      }
  }
}
</style>
