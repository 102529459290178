import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/index.less"
import App from './App.vue'
Vue.use(ElementUI);
import "./assets/icon/iconfont.css"
Vue.config.productionTip = false
import router from "./router.js"
import less from 'less'
Vue.use(less)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title || "运营兽"
  }
  next()
  window.scroll(0,0)
})
new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
