<template>
  <div id="app">
    <div class="home" v-if="!isMobile">
      <div style="width:100%;height:100px"></div>
      <div class="header">
        <div class="header_content">
          <img src="./assets/img/operateAnimalLogo_177x60.png" alt srcset />
          <nav>
            <router-link to="/" >首页</router-link>
          <a @click="scrollIntoView">电商运营</a>
          <a href="https://www.zhishuyao.com/download" target="_blank">电商软件</a>
          <router-link to="/about">关于我们</router-link>
          </nav>
        </div>
      </div>
      <router-link to="/"></router-link>
      <router-view></router-view>
      <div class="column_foot">
        <div class="foot_content">
          <img src="./assets/img/operateAnimalLogo_131x160.png" alt srcset />
          <div class="content_url">
            <div class="content_url_top">
              <p>
                <span>妖兽科技</span>
                <a href="https://www.zhishuyao.com/download"  target="_blank">指数妖</a>
                <a href="#">运营兽</a>
              </p>
              <p>
                <span>用户支持</span>
                <a href="#">联系我们</a>
                <a href="#">帮助中心</a>
                <a href="#">用户协议</a>
              </p>
              <p>
                <span>妖兽团队</span>
                <router-link to="/about">关于我们</router-link>
                <a href="#">加入我们</a>
              </p>
            </div>
            <div class="content_url_bottom">
              <p>Copyright © 2019-2021 <a href="//www.yunyingshou.com" target="__blank" title="运营兽">运营兽</a></p>
              <p><a href="https://beian.miit.gov.cn" target="_blank">浙ICP备20017027-3号</a></p>
            </div>
          </div>
          <img src="./assets/img/QRCODE_160x160.jpg" alt srcset />
        </div>
      </div>
    </div>
    <div class="mobile_yunyingshou" v-if="isMobile">
      <router-link to="/mobile/home"></router-link>
      <router-view></router-view>

      <footer>
        <div :class="currentRouter == '/mobile/home' ? 'active_router' : ''">
          <router-link to="/mobile/home" >
            <p><i class="iconfont icon-shouye"></i></p>
            <p>首页</p>
          </router-link>
        </div>
        <div :class="currentRouter == '/mobile/yunying' ? 'active_router' : ''">
          <a @click="scrollIntoView" >
            <p><i class="iconfont icon-baobiao"></i></p>
            <p>电商运营</p>
          </a>
        </div>
        <div :class="currentRouter == '/mobile/ruajian' ? 'active_router' : ''">
          <a href="https://www.zhishuyao.com/download">
            <p><i class="iconfont icon-update"></i></p>
            <p>电商软件</p>
          </a>
        </div>
        <div :class="currentRouter == '/mobile/about' ? 'active_router' : ''">
          <router-link to="/mobile/about" >
            <p><i class="iconfont icon-xiaohuoban"></i></p>
            <p>关于我们</p>
          </router-link>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      isMobile:false,
      currentRouter:"",
    }
  },
  methods:{
    scrollIntoView() {
      if(this.$route.path == "/about")this.$router.replace('/')
      else if(this.$route.path == "/mobile/about") this.$router.replace('/mobile/home');
      let that = this
      var timer=setInterval(function (){
        if(document.getElementsByClassName("column_serviceA")){
          that.$el
              .querySelector(".column_serviceA")
              .scrollIntoView({ block: "start", behavior: "smooth" });
        }
        clearInterval(timer)

      },500);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted(){
    this.isMobile = this._isMobile()
    if (this.isMobile && !window.location.href.includes('mobile')) {
      // alert("手机端");
      this.$router.replace('/mobile/home');
    } else {
      // alert("pc端");
      // this.$router.replace('/pc_index');
    }
    this.currentRouter =this.$route.path;
  },
  watch: {
    $route: function(to) {
      this.currentRouter = to.path;
    }
  },
};``
</script>

<style lang="less">
/* @import './assets/css/font.css';
@import './assets/css/font_b.css'; */
#app {
  font-family: "arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   a{
    text-decoration:none;
  }


  .home {
    font-family: PingFangSC-Regular, PingFang SC;
    .header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background: hsla(0,0%,100%,.95);
    -webkit-box-shadow: 0 2px 54px 0 rgb(0 0 0 / 7%);
    box-shadow: 0 2px 54px 0 rgb(0 0 0 / 7%);
    width: 100%;
    .header_content{
      width: 1320px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      height: 100px;
      nav {
        display: flex;
        height: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        a {
          display: block;
          color: #333333;
          text-decoration: none;
          padding: 0 30px;
          font-size: 24px;
          font-weight: bold;
          line-height: 17px;
          height: 20px;
          border-right: 2px solid #333333;
          &:first-child {
            margin-left: 0 !important;
          }
          &:last-child {
            margin-right: 0 !important;
            border-right: 0;

          }
        }
      }

    }
  }
    .column_foot {
      background: url(./assets/img/bg_center_gray.png);
      background-repeat: no-repeat;
      padding-top: 260px;
      height: 414px;
      background-size: 100% 144%;
      .foot_content {
        width: 1320px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        img {
          height: 160px;
        }
        .content_url {
          .content_url_top {
            display: flex;
            justify-content: space-between;
            width: 568px;
            p {
              width: 56px;
              font-size: 14px;
              span {
                color: #333333;
                font-weight: 700;
                line-height: 20px;
              }
              a {
                display: block;
                margin: 20px 0;
                line-height: 20px;
                color: #999999;
              }
            }
          }
          .content_url_bottom {
            margin: auto;
            margin-top: 80px;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            color: #999999;
            a{
              color: #999999;
            }
          }
        }
      }
    }
  }
  .mobile_yunyingshou{
    margin: 0;
    padding: 0 0 50px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    header{
      position: absolute;
    }
    footer{
      position: fixed;
      background: #fff;
      bottom: 0;
      height: 50px;
      width: 100%;
      display: flex;
      left: 0;
      box-shadow: 0px 4px 16px 0px rgba(177, 177, 177, 0.26);
      justify-content: space-around;
      text-align: center;
      z-index: 10;
      font-size: 12px;
      .active_router{
        p{
          color: #44A7FC;
        }
      }
      div{
        p{
          font-family: PingFang SC;
          background: #fff;
          font-weight: 500;
          color: #787878;
          margin: 0;
          &:first-child{
            margin-top: 6px;
          }
          &:last-child{
            margin-top: 5px;
          }
        }
        width: 25%;
      }
    }
  }
}
</style>
