
import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import home from "./components/pc/Home.vue";
import mHome from "./components/mobile/Home.vue";

import about from "./components/pc/About.vue";
import mAbout from "./components/mobile/About.vue";
// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);


const routes = [
    {
        path:"/about",
        component: about,
        meta:{
            title: '关于我们'
        }
    },
    {
        path:"/home",
        component: home,
        meta:{
            title: '运营兽'
        }
    },
    {
        path:"/mobile/home",
        component: mHome,
        meta:{
            title: '运营兽'
        }
    },
    {
        path:"/mobile/about",
        component: mAbout,
        meta:{
            title: '关于我们'
        }
    },
    {
        path: '/',
        redirect:'/home',
        component: home,
        meta: {
            title: '首页'
        }
    }
]

var router =  new VueRouter({
    mode: 'history',
    base:process.env.BASE_URL,
    routes
})
export default router;
