<template>
  <div class="home">
    <!-- <div style="width:100%;height:100px"></div>
    <div class="header">
      <div class="header_content">
        <img src="./../assets/img/operateAnimalLogo_177x60.png" alt srcset />
        <nav>
          <a href>首页</a>
          <a href>电商运营</a>
          <a href>电商软件</a>
          <a href>关于我们</a>
        </nav>
      </div>
    </div>-->
    <div class="column">
      <div class="columnContent">
        <div class="columnContentTop">
          <div>
            <h1>专业的电商运营</h1>
            <p>懂车兽长期服务于淘宝、天猫、京东、拼多多等平台，提供全方位的店铺运营服务。通过专业的店铺诊断、付费推广、数据分析，有效提高店铺运营效率，助力店铺打造爆款。</p>
            <a @click="scrollIntoView">预约免费诊断</a>
          </div>
          <img src="../../assets/img/home_0.png" alt srcset />
        </div>
        <div class="column_serviceA"></div>
        <div class="columnContentBottom ">
          <h1>服务内容</h1>
          <p>为您提供专业的淘宝、天猫、拼多多等平台代运营服务以及直通车、超级推荐、巨量引擎等推广平台的托管服务。</p>
        </div>
      </div>
    </div>
    <div class="column_service">
      <span>独创的预诊断及试运营业务模式，为您创造零风险的服务体验。</span>
      <div class="service_content">
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/taobao.png" alt srcset />
              <img src="../../assets/img/serviceLogo/tianmao.png" alt srcset />
            </div>
            <h3>淘宝/天猫代运营</h3>
            <p>为您提供专业的店铺数据分析、竞品 监控、直超优化、搜索优化、爆款打 造及天猫入驻等服务。</p>
          </div>
        </div>
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/tianmao.png" alt srcset />
            </div>
            <h3>拼多多代运营</h3>
            <p>拼多多专业团队，集合爆款定价，首 页、推文、爱逛街资源位申报，拼多 多推广等为一体的一站式运营服务。</p>
          </div>
        </div>
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/jingdong.png" alt srcset />
            </div>
            <h3>京东代运营</h3>
            <p>京东专业团队，提供全店代运营、活 动策划执行、京东快车投放、京东入 驻等服务。</p>
          </div>
        </div>
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/zhitongche.png" alt srcset />
            </div>
            <h3>直通车投放托管</h3>
            <p>专业的直通车选款、关键词优化、人 群标签优化、时段、地域多条件综合 优化，助力投产提升、搜索突围。</p>
          </div>
        </div>
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/tianmao.png" alt srcset />
            </div>
            <h3>超级推荐投放托管</h3>
            <p>基于淘宝信息流推荐场景标签化的专 业投放，有效提高投入产出比，助力 提升推荐流量，拉动全店动销。</p>
          </div>
        </div>
        <div class="out_card">
          <div class="service_card">
            <div>
              <img src="../../assets/img/serviceLogo/juliangyinqing.png" alt srcset />
            </div>
            <h3>超级推荐投放托管</h3>
            <p>针对投放目标进行精准的流量平台选 择，通过多元创意进行精准的人群触 达，帮助商品实现转化闭环。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="column_team">
      <div class="columnContent">
        <div class="columnContentTop">
          <img src="../../assets/img/home_1.png" alt srcset />
          <div>
            <h1>成熟团队 专业用心</h1>
            <p>12年电商经验为店铺业绩保驾护航 熟悉天猫、京东、拼多多等个平台玩法 擅长直通车、超级推荐、精准通等推广工具</p>
          </div>
        </div>
      </div>
    </div>
    <div class="column_persn">
      <div>
        <h1>专人操盘 专项运营</h1>
        <p>
          专人专项的项目运营制度
          <br />运营专员全程跟踪店铺运营情况
          <br />实时进行有针对性的运营策略调整
        </p>
      </div>
      <img src="../../assets/img/home_2.png" alt srcset />
    </div>
    <div class="column_report">
      <div class="columnContent">
        <div class="columnContentTop">
          <img src="../../assets/img/home_3.png" alt srcset />
          <div>
            <h1>运营周期 定期汇报</h1>
            <p>
              每周汇总运营数据报表
              <br />根据当周数据进行行业对比
              <br />定期将数据情况向甲方进行汇报
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column_know">
      <div>
        <h1>知己知彼 百战不殆</h1>
        <p>
          根据自身产品定位锁定竞品
          <br />持续跟踪竞争对手运营数据
          <br />根据竞品运营情况即使策略调整
        </p>
      </div>
      <img src="../../assets/img/home_4.png" alt srcset />
    </div>
    <div class="column_cooperativeBrand">
      <h1>合作品牌</h1>
      <div class="bg_tv">
        <div class="imgs-container">
          <div class="imgs-containers imgs-container_1" :style="handleStyle(0)"></div>
          <div class="imgs-containers imgs-container_2" :style="handleStyle(1)"></div>
          <div class="imgs-container_3 imgs-containers" :style="handleStyle(2)"></div>
          <div class="imgs-container_4 imgs-containers" :style="handleStyle(3)"></div>
          <div class="imgs-container_5 imgs-containers" :style="handleStyle(4)"></div>
          <div class="imgs-container_6 imgs-containers" :style="handleStyle(5)"></div>
          <div class="imgs-container_7 imgs-containers" :style="handleStyle(6)"></div>
          <div class="imgs-container_8 imgs-containers" :style="handleStyle(7)"></div>
          <div class="imgs-container_9 imgs-containers" :style="handleStyle(8)"></div>
          <div class="imgs-container_10 imgs-containers" :style="handleStyle(9)"></div>
          <div class="imgs-container_11 imgs-containers" :style="handleStyle(10)"></div>
          <div class="imgs-container_12 imgs-containers" :style="handleStyle(11)"></div>
          <div class="imgs-container_13 imgs-containers" :style="handleStyle(12)"></div>
          <div class="imgs-container_14 imgs-containers" :style="handleStyle(13)"></div>
          <div class="imgs-container_15 imgs-containers" :style="handleStyle(14)"></div>
          <div class="imgs-container_16 imgs-containers" :style="handleStyle(15)"></div>
          <div class="imgs-container_17 imgs-containers" :style="handleStyle(16)"></div>
          <div class="imgs-container_32 imgs-containers" :style="handleStyle(17)"></div>
          <div class="imgs-container_19 imgs-containers" :style="handleStyle(18)"></div>
          <div class="imgs-container_20 imgs-containers" :style="handleStyle(19)"></div>
          <div class="imgs-container_21 imgs-containers" :style="handleStyle(20)"></div>
          <div class="imgs-container_22 imgs-containers" :style="handleStyle(21)"></div>
          <div class="imgs-container_23 imgs-containers" :style="handleStyle(22)"></div>
          <div class="imgs-container_24 imgs-containers" :style="handleStyle(23)"></div>
          <div class="imgs-container_25 imgs-containers" :style="handleStyle(24)"></div>
          <div class="imgs-container_27 imgs-containers" :style="handleStyle(25)"></div>
          <div class="imgs-container_26 imgs-containers" :style="handleStyle(26)"></div>
          <div class="imgs-container_28 imgs-containers" :style="handleStyle(27)"></div>
          <div class="imgs-container_29 imgs-containers" :style="handleStyle(28)"></div>
          <div class="imgs-container_30 imgs-containers" :style="handleStyle(29)"></div>
          <div class="imgs-container_31 imgs-containers" :style="handleStyle(30)"></div>
          <div class="imgs-container_18 imgs-containers" :style="handleStyle(31)"></div>
          <div class="imgs-container_33 imgs-containers" :style="handleStyle(32)"></div>
        </div>
      </div>
      <div class="dingyue_box" ref="dingyue_box"></div>
    </div>
    <div class="column_diagnosis">
      <h1>免费诊断</h1>
      <p>请准确填写以下内容，我们将安排对应类目的运营经理为您诊断店铺，我们将于半个小时内与您取得联系。</p>
      <div>
        <p class="trueplaceholder">
          <input
            type="text"
            style="color:#666666"
            v-model="shopMsg.shopUrl"
            placeholder="请输入店铺地址"
            @keyup="changePhone('shopUrl')"
            @blur="changePhone('shopUrl')"
          />
        </p>
        <p class="input_center trueplaceholder">
          <input
            type="text"
            @keyup="changePhone('name')"
            @blur="changePhone('name')"
            v-model="shopMsg.name"
            style="color:#666666"
            placeholder="请输入您的称呼"
          />
        </p>
        <p class="input_center" :class="this.rules.phone?'trueplaceholder':'falseplaceholder'">
          <input
            type="text"
            :style="this.rules.phone?'color:#666666':'color:red'"
            @keyup="changePhone('phone')"
            @blur="changePhone('phone')"
            v-model="shopMsg.phone"
            placeholder="请输入联系电话"
          />
        </p>
        <button @click="submitForm">预约诊断</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      style: [
        {
          x: -72.3232,
          y: 40.8388,
          oldy: 40.8388,
          up: false
        },
        {
          x: 415.916,
          y: 283.536,
          oldy: 283.936,
          up: true
        },
        {
          x: 547.335,
          y: 350.569,
          oldy: 350.569,
          up: false
        },
        {
          x: 128.89,
          y: 93.9941,
          oldy: 93.9941,
          up: false
        },
        {
          x: 1518.79,
          y: 326.47,
          oldy: 326.47,
          up: true
        }, //5
        {
          x: 1056.3,
          y: 328.846,
          oldy: 328.846,
          up: false
        },
        {
          x: 2581.3,
          y: 1230,
          oldy: 846,
          up: false
        },
        {
          x: 2063.71,
          y: 252.077,
          oldy: 252.077,
          up: false
        },
        {
          x: 7.319,
          y: 384.698,
          oldy: 364.698,
          up: true
        },
        {
          x: 2242.18,
          y: 192.954,
          oldy: 191.954,
          up: true
        }, //10
        {
          x: 1327.14,
          y: 380.698,
          oldy: 370.592,
          up: false
        },
        {
          x: 83.88,
          y: 203.847,
          oldy: 204.884,
          up: false
        },
        {
          x: 2705.89,
          y: 99.288,
          oldy: 99.288,
          up: true
        },
        {
          x: 2451.75,
          y: 310.369,
          oldy: 300.369,
          up: false
        },
        {
          x: 2121.42,
          y: 65.393,
          oldy: 55.393,
          up: false
        }, //15
        {
          x: 1938.11,
          y: 337.424,
          oldy: 312.424,
          up: false
        },
        {
          x: 243.224,
          y: 359.087,
          oldy: 359.087,
          up: false
        },
        {
          x: 740.766,
          y: 308.364,
          oldy: 300.364,
          up: true
        },
        {
          x: 946.786,
          y: 188.63,
          oldy: 188.63,
          up: false
        },
        {
          x: 1084.89,
          y: 75.2581,
          oldy: 75.2581,
          up: false
        }, //20
        {
          x: 1451.92,
          y: 157.759,
          oldy: 157.759,
          up: true
        },
        {
          x: 1590.72,
          y: 53.6695,
          oldy: 53.6695,
          up: false
        },
        {
          x: 335.091,
          y: 117.631,
          oldy: 107.631,
          up: false
        },
        {
          x: 2850.425,
          y: 240.038,
          oldy: 240.038,
          up: false
        },
        {
          x: 595.951,
          y: 161.782,
          oldy: 161.782,
          up: false
        }, //25
        {
          x: 912.739,
          y: 390.191,
          oldy: 390.191,
          up: false
        },
        {
          x: 1222.34,
          y: 222.461,
          oldy: 222.461,
          up: false
        },
        {
          x: 2713.88,
          y: 369.884,
          oldy: 369.884,
          up: false
        },
        {
          x: 809.562,
          y: 89.2548,
          oldy: 89.2548,
          up: false
        },
        {
          x: 2438.54,
          y: 110.13,
          oldy: 110.13,
          up: false
        }, //30
        {
          x: 1870.2,
          y: 100.13,
          oldy: 100.13,
          up: false
        },
        {
          x: 2217.99,
          y: 359.567,
          oldy: 359.567,
          up: false
        },
        {
          x: 1710.2,
          y: 259.187,
          oldy: 259.187,
          up: false
        }
      ],
      shopMsg: {
        shopUrl: "",
        name: "",
        phone: ""
      },
      rules: {
        name: true,
        shopUrl: true,
        phone: true
      }
    };
  },
  methods: {
    actionMove() {
      let _this = this;
      this.timeout = setInterval(() => {
        _this.queryConvertPercent();
      }, 25);
    },
    queryConvertPercent() {
      for (let i = 0; i < this.style.length; i++) {
        if (this.style[i].x > 0) {
          this.style[i].x -= 0.45;
          if (this.style[i].y - this.style[i].oldy > 10) {
            this.style[i].up = true;
          }
          if (this.style[i].y - this.style[i].oldy < -10) {
            this.style[i].up = false;
          }
          if (this.style[i].up) this.style[i].y -= 0.25;
          else this.style[i].y += 0.25;
        } else {
          this.style[i].x = 3000;
          if (this.style[i].y - this.style[i].oldy > 30)
            this.style[i].y -= 0.45;
          else this.style[i].y += 0.45;
        }
      }
    },
    scrollIntoView() {
      this.$el
        .querySelector(".dingyue_box")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    handleStyle(index) {
      if (index < 10)
        return {
          opacity: 1,
          transform:
            "translate(" +
            this.style[index].x +
            "px," +
            this.style[index].y +
            "px) scale(0.6)"
        };
      else if (index > 10 && index < 21)
        return {
          opacity: 1,
          transform:
            "translate(" +
            this.style[index].x +
            "px," +
            this.style[index].y +
            "px) scale(0.8)"
        };
      else if (index > 21)
        return {
          opacity: 1,
          transform:
            "translate(" +
            this.style[index].x +
            "px," +
            this.style[index].y +
            "px) scale(1)"
        };
    },
    submitForm() {
      let regs = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      let passRule = true;
      if (!regs.test(this.shopMsg.phone)) passRule = false;
      if (passRule) {
        axios({
          method: "post",
          url: "https://console.zhishuyao.com/index/tool/apply",
          data: {
            address: this.shopMsg.shopUrl,
            username: this.shopMsg.name,
            mobile: this.shopMsg.phone
          }
        }).then(res => {
          let code = res.data.code;
          if (code == "0") {
            this.showPage = "home";
            this.$message({
              message: "预约成功",
              type: "success"
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        });
      } else {
        this.$message({
          message: "手机号码格式不正确，请重新输入",
          type: "warning"
        });
      }
    },
    changePhone(msg) {
      if (!this.shopMsg[msg]) this.rules[msg] = false;
      else this.rules[msg] = true;
    }
  },
  created() {
    this.actionMove();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.home {
  font-family: PingFangSC-Regular, PingFang SC;
  .header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background: hsla(0, 0%, 100%, 0.95);
    -webkit-box-shadow: 0 2px 54px 0 rgb(0 0 0 / 7%);
    box-shadow: 0 2px 54px 0 rgb(0 0 0 / 7%);
    width: 100%;
    .header_content {
      width: 1320px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      height: 100px;
      nav {
        display: flex;
        height: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        a {
          display: block;
          color: #333333;
          text-decoration: none;
          padding: 0 30px;
          font-size: 24px;
          font-weight: bold;
          line-height: 17px;
          height: 20px;
          border-right: 2px solid #333333;
          &:first-child {
            margin-left: 0px !important;
          }
          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
  .column {
    background: url(../../assets/img/bg_center_gray.png);
    background-repeat: no-repeat;
    // background-size:cover;
    margin-top: 72px;
    height: 946px;
    background-size: 100% 100%;
    .columnContent {
      width: 1320px;
      margin: auto;
      .columnContentTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        div {
          width: 468px;
          h1 {
            margin-top: 118px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #333333;
            font-weight: 600;
            font-size: 60px;
            line-height: 84px;
          }
          p {
            color: #666666;
            font-size: 18px;
            line-height: 33px;
            margin: 50px 0;
          }
          a {
            display: block;
            width: 311.18px;
            height: 56.65px;
            border-radius: 56.65px;
            color: #ffffff;
            background-color: #44a7fc;
            cursor: pointer;
            font-size: 24px;
            text-align: center;
            line-height: 56.65px;
          }
        }
        img {
          width: 700px;
          margin-top: -22px;
        }
      }
      .columnContentBottom {
        margin: auto;
        margin-top: 214px;
        width: 1200px;
        h1 {
          width: 240px;
          height: 84px;
          font-size: 60px;
          font-weight: 600;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #333333;
          font-weight: 600;
          line-height: 84px;
          text-align: center;
          margin: auto;
        }
        p {
          font-size: 24px;
          line-height: 33px;
          margin-top: 30px;
          color: #333333;
          width: 1200px;
        }
      }
    }
  }
  .column_serviceA{
    //margin-top: -100px;
    //margin-bottom: 100px;
    position: relative;
    top:105px;
  }
  .column_service {
    width: 1320px;
    color: #333333;
    margin: auto;
    span {
      margin: auto;
      display: inline-block;
      font-size: 24px;
      line-height: 33px;
      width: 848px;
      margin-left: 322px;
    }
    .service_content {
      display: flex;
      justify-content: space-around;
      align-content: space-between;
      flex-wrap: wrap;
      height: 880px;
      margin-top: 50px;
      .out_card {
        width: 358px;
        padding: 32px;
        &:first-child {
          .service_card {
            div {
              display: flex;
              width: 170px;
              justify-content: space-between;
              margin: auto;
              img {
                width: 80px;
              }
            }
          }
        }
        .service_card {
          height: 301px;
          padding-top: 50px;
          box-shadow: 0 0 9px 3px #eae9e9;

          div {
            width: 80px;
            margin: auto;
            height: 80px;
            img {
              height: 80px;
            }
          }
          h3 {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            margin: 30px 0;
            font-size: 24px;
            line-height: 33px;
            text-align: center;
          }
          p {
            width: 288px;
            padding: 0 35px;
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .column_team {
    background: url(../../assets/img/bg_center_gray.png);
    background-repeat: no-repeat;
    height: 711px;
    background-size: 100% 100%;
    padding-top: 253px;
    .columnContent {
      width: 1320px;
      margin: auto;
      .columnContentTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        div {
          width: 456px;
          h1 {
            color: #333333;
            font-size: 48px;
            line-height: 67px;
          }
          p {
            font-size: 24px;
            line-height: 33px;
            margin: 30px 0;
            color: #666666;
          }
        }
        img {
          width: 500px;
          height: 401px;
          margin-top: -68px;
        }
      }
    }
  }
  .column_persn {
    width: 1320px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    div {
      h1 {
        font-size: 48px;
        line-height: 67px;
        color: #333333;
        font-weight: 600;
      }
      p {
        margin-top: 30px;
        color: #666666;
        font-size: 24px;
        line-height: 33px;
      }
    }
    img {
      width: 500px;
      height: 366.15px;
      margin: -85px 0 54px 0;
    }
  }
  .column_report {
    background: url(../../assets/img/bg_center_gray.png);
    background-repeat: no-repeat;
    height: 688px;
    background-size: 100% 100%;
    padding-top: 258px;
    .columnContent {
      width: 1320px;
      margin: auto;
      .columnContentTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        div {
          width: 456px;
          h1 {
            color: #333333;
            font-size: 48px;
            line-height: 67px;
          }
          p {
            font-size: 24px;
            line-height: 33px;
            margin: 30px 0;
            color: #666666;
          }
        }
        img {
          width: 500px;
          height: 373.9px;
          margin-top: -31px;
        }
      }
    }
  }
  .column_know {
    width: 1320px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    div {
      h1 {
        font-size: 48px;
        line-height: 67px;
        color: #333333;
        font-weight: 600;
      }
      p {
        margin-top: 30px;
        color: #666666;
        font-size: 24px;
        line-height: 33px;
      }
    }
    img {
      width: 500px;
      height: 486.4px;
      margin: -145px 0 197px 0;
    }
  }
  .column_cooperativeBrand {
    h1 {
      font-size: 60px;
      color: #333333;
      text-align: center;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 84px;
    }
  }
  .column_foot {
    background: url(../../assets/img/bg_center_gray.png);
    background-repeat: no-repeat;
    padding-top: 260px;
    height: 704px;
    background-size: 100% 100%;
    .foot_content {
      width: 1320px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      img {
        height: 160px;
      }
      .content_url {
        .content_url_top {
          display: flex;
          justify-content: space-between;
          width: 568px;
          p {
            width: 56px;
            font-size: 14px;
            span {
              color: #333333;
              font-weight: 700;
              line-height: 20px;
            }
            a {
              display: block;
              margin: 20px 0;
              line-height: 20px;
              color: #999999;
            }
          }
        }
        .content_url_bottom {
          margin: auto;
          margin-top: 80px;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
      }
    }
  }
  .bg_tv {
    background: url(../../assets/img/bg_center_gray.png);
    background-repeat: no-repeat;
    height: 711px;
    background-size: 100% 100%;
    position: relative;
    width: 100%;
    overflow: hidden;
    .imgs-container {
      width: 3000px;
      height: 460px;
      top: 50px;
      left: -200px;
      position: absolute;
      z-index: 2;
      .imgs-containers {
        background: url(../../assets/img/tokens.e93c29d.png);
        background-repeat: no-repeat;
        height: 120px;
        position: absolute;
        width: 120px;
        background-size: 1353px 492px;
      }
      .imgs-container_1 {
        background-position: -0px -0px;
      }
      .imgs-container_2 {
        background-position: -0px -123px;
      }
      .imgs-container_3 {
        background-position: -0px 246px;
      }
      .imgs-container_4 {
        background-position: -0px -369px;
      }
      .imgs-container_5 {
        background-position: -123px -0px;
      }
      .imgs-container_6 {
        background-position: -123px -123px;
      }
      .imgs-container_7 {
        background-position: -123px -246px;
      }
      .imgs-container_8 {
        background-position: -123px -369px;
      }
      .imgs-container_9 {
        background-position: -246px -0px;
      }
      .imgs-container_10 {
        background-position: -246px -123px;
      }
      .imgs-container_11 {
        background-position: -246px -246px;
      }
      .imgs-container_12 {
        background-position: -246px -369px;
      }
      .imgs-container_13 {
        background-position: -369px -0px;
      }
      .imgs-container_14 {
        background-position: -369px -123px;
      }
      .imgs-container_15 {
        background-position: -369px -246px;
      }
      .imgs-container_16 {
        background-position: -369px -369px;
      }
      .imgs-container_17 {
        background-position: -492px -0px;
      }
      .imgs-container_18 {
        background-position: -492px -123px;
      }
      .imgs-container_19 {
        background-position: -492px -246px;
      }
      .imgs-container_20 {
        background-position: -492px -369px;
      }
      .imgs-container_21 {
        background-position: -615px -0px;
      }
      .imgs-container_22 {
        background-position: -615px -123px;
      }
      .imgs-container_23 {
        background-position: -615px -246px;
      }
      .imgs-container_24 {
        background-position: -615px -369px;
      }
      .imgs-container_25 {
        background-position: -738px -0px;
      }
      .imgs-container_26 {
        background-position: -738px -123px;
      }
      .imgs-container_27 {
        background-position: -738px -246px;
      }
      .imgs-container_28 {
        background-position: -738px -369px;
      }
      .imgs-container_29 {
        background-position: -861px -0px;
      }
      .imgs-container_30 {
        background-position: -861px -123px;
      }
      .imgs-container_31 {
        background-position: -861px -246px;
      }
      .imgs-container_32 {
        background-position: -861px -369px;
      }
      .imgs-container_33 {
        background-position: -984px -0px;
      }
      .imgs-container_4 {
        background-position: -984px -123px;
      }
      .imgs-container_4 {
        background-position: -984px -246px;
      }
      .imgs-container_4 {
        background-position: -984px -369px;
      }
    }
  }
  .dingyue_box {
    margin-top: -100px;
    margin-bottom: 100px;
  }
  .column_diagnosis {
    width: 1320px;
    margin: auto;
    h1 {
      text-align: center;
      font-size: 60px;
      line-height: 84px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    p {
      color: #666666;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      margin-top: 30px;
    }
    div {
      padding: 50px 0 100px;
      p {
        margin: auto;
        width: 460px;
        height: 66px;
        box-shadow: 0px 13px 13px -3px #eae9e9;
        border-radius: 66px;
        input {
          margin: 5px 0 5px 40px;
          height: 56px;
          font-size: 24px;
          line-height: 33px;
          border: 0;
          outline: none;
          width: 390px;
        }
      }
      button {
        width: 447px;
        height: 52px;
        background-color: #44a7fc;
        color: #ffffff;
        border: 0;
        font-size: 24px;
        cursor: pointer;
        outline: none;
        margin: auto;
        border-radius: 52px;
        display: block;
        margin-top: 50px;
      }
      .input_center {
        margin-top: 30px;
      }
    }
  }
}
.trueplaceholder {
  ::placeholder {
    /* WebKit browsers */
    color: #999999 !important;
  }
}
.falseplaceholder {
  ::placeholder {
    /* WebKit browsers */
    color: red !important;
    color: red;
  }
}
</style>
